var ContactForm = function () {

    return {

        //Contact Form
        initContactForm: function () {
            // Validation
            $("#sky-form3").validate({
                // Rules for form validation
                rules: {
                    name: {
                        required: true
                    },
                    email: {
                        required: true,
                        email: true
                    },
                    message: {
                        required: true,
                        minlength: 10
                    },
                    captcha: {
                        required: true,
                        remote: 'assets/plugins/sky-forms/version-2.0.1/captcha/process.php'
                    }
                },

                // Messages for form validation
                messages: {
                    name: {
                        required: 'Please enter your name',
                    },
                    email: {
                        required: 'Please enter your email address',
                        email: 'Please enter a VALID email address'
                    },
                    message: {
                        required: 'Please enter your message'
                    },
                    captcha: {
                        required: 'Please enter characters',
                        remote: 'Correct captcha is required'
                    }
                },

                // Ajax form submition
                submitHandler: function (form) {
                    $(form).ajaxSubmit(
                        {
                            beforeSend: function () {
                                $('#sky-form3 button[type="submit"]').attr('disabled', true);
                                $('#sky-form3 button[type="submit"]').html('Sending...')
                            },
                            success: function ($r) {
                                $("#sky-form3").addClass('submited');

                                if ($r == 1) {
                                    $("#sky-form3 .error").hide();
                                }
                                else {
                                    $("#sky-form3 .correct").hide();
                                }
                            }
                        });
                },

                // Do not change code below
                errorPlacement: function (error, element) {
                    error.insertAfter(element.parent());
                }
            });
        }

    };

}();
